<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)" variant="primary" block
        @click="$emit('update:isEventHandlerSidebarActive', true)">
        + Add new booking
      </b-button> -->
      <div class="mt-3">
        <h5 class="app-label section-label mt-1">
          <span class="align-middle">Hospital</span>
        </h5>
        <v-select id="hospital" v-model="selectedClient" :clearable="false" label="text" :options="clients"
          :reduce="option => option.value" />

      </div>



    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import Parse from "parse/dist/parse.min.js";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    vSelect
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      selectedClient,
      selectedStatus,

      checkAll,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      selectedClient,
      selectedStatus,
      checkAll,
    }
  },
  data() {
    return {
      clients: [],
    }
  },
  created() {
    this.fetchClients()
    this.fetchUsers()
  },
  methods: {
    fetchClients() {
      const Client = Parse.Object.extend("Client");
      const query = new Parse.Query(Client);
      query
        .find()
        .then((results) => {
          this.clients = results.map(client => ({
            value: client.id,
            text: `${client.attributes.name} ${client.attributes.department}`
          }));
          this.clients.unshift({
            value: "", text: "All"
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching client list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchUsers() {
      const query = new Parse.Query(Parse.Object.extend("User"));
      query
        .find()
        .then((results) => {
          this.assistants = results.map(user => ({
            value: user.id,
            text: `${user.attributes.firstName} ${user.attributes.lastName}`
          }));
          this.assistants.unshift({
            value: "", text: "All"
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching user list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  }
}
</script>

<style>

</style>
