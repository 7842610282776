<template>
  <div>
    <b-sidebar id="sidebar-add-new-event" sidebar-class="sidebar-lg" :visible="isEventHandlerSidebarActive"
      bg-variant="white" shadow backdrop no-header right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)">
      <template #default="{ hide }">
        <b-overlay :show="loading || loadingLogs" rounded="sm">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              Activity Log
            </h5>
            <div>
              <feather-icon v-if="eventLocal.id" icon="TrashIcon" class="cursor-pointer"
                @click="$emit('remove-event'); hide();" />
              <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
          </div>
          <div class="p-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="w-100"
              @click="resetForm(); showForm = !showForm">
              + Log a task
            </b-button>
          </div>

          <div v-if="showForm">

            <!-- Body -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

              <!-- Form -->
              <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                <b-row>
                  <b-col md="6">
                    <!-- Doctor -->
                    <validation-provider #default="validationContext" name="Doctor" rules="required">
                      <b-form-group label="Doctor" label-for="doctor" :state="getValidationState(validationContext)">
                        <b-form-input id="doctor" v-model="log.doctor" autofocus
                          :state="getValidationState(validationContext)" trim />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <!-- Patient -->
                    <validation-provider #default="validationContext" name="Patient" rules="required">
                      <b-form-group label="Patient" label-for="patient" :state="getValidationState(validationContext)">
                        <b-form-input id="patient" v-model="log.patient" autofocus
                          :state="getValidationState(validationContext)" trim />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <!-- Comments -->
                    <validation-provider #default="validationContext" name="Comments" rules="required">
                      <b-form-group label="Comments" label-for="comments" :state="getValidationState(validationContext)">
                        <b-form-input id="comments" v-model="log.comments" autofocus
                          :state="getValidationState(validationContext)" trim />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="w-100"
                      @click="resetForm">
                      <feather-icon icon="XIcon" size="14" class="button-icon" />Cancel
                    </b-button>
                  </b-col>
                  <b-col md="6">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="w-100" type="submit">
                      <feather-icon icon="SaveIcon" size="14" class="button-icon" />Save Change
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
          <div class="divider my-2">
            <div class="divider-text">
              Task Logs
            </div>
          </div>
          <div class="m-2">
            <div v-for="log in logs" :key="log.id" class="log-container my-1">
              <div class="header">
                <b-img :src="require('@/assets/images/doctor.png')" class="icon" />
                <span class="name" style="margin-right: 16px;">{{ log.attributes.doctor }}</span>
                <b-img :src="require('@/assets/images/patient.png')" class="icon" />
                <span class="name" style="flex: 1">{{ log.attributes.patient }}</span>

                <feather-icon icon="EditIcon" class="cursor-pointer" size="16" @click="editLog(log)" />
                <feather-icon icon="TrashIcon" class="cursor-pointer" size="16" @click="deleteLog(log)" />
              </div>
              <div class="p-1">
                <p class="comment">{{ log.attributes.comments }}</p>
                <p class="updatedAt">{{ log.updatedAt }}</p>
              </div>
            </div>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback, BRow, BCol, BModal, BOverlay, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'
import Parse from "parse/dist/parse.min.js";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from 'vue-cleave-component'
import { convertMinToHr } from "@core/utils/utils";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BOverlay,
    BImg,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    Cleave,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  watch: {
    eventLocal: function (newVal, oldVal) {
      if (newVal.id) {
        this.fetchBookingDetails()
        this.fetchLogs()
      }
      else {
        this.time = "00:00"
      }
    }
  },
  data() {
    return {
      required,
      email,
      url,
      users: [],
      clients: [],
      reason: '',
      reasonState: null,
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
      loading: false,
      loadingLogs: false,
      log: {
        doctor: "",
        patient: "",
        comments: "",
        id: ""
      },
      showForm: false,
      logs: []
    }
  },
  created() {
  },
  methods: {
    resetForm() {
      this.log = {
        doctor: "",
        patient: "",
        comments: "",
        id: ""
      }
      this.showForm = false
    },
    // onBlur() {
    //   const Booking = Parse.Object.extend("Booking");
    //   const booking = new Booking();
    //   booking.set("objectId", this.eventLocal.id);
    //   booking.set("time", this.time);
    //   booking.save().then((response) => {
    //     this.toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Time Updated",
    //         icon: "CheckIcon",
    //         variant: "success",
    //       },
    //     });
    //   })
    // },
    fetchBookingDetails() {
      this.loading = true

      const Booking = Parse.Object.extend("Booking");
      const query = new Parse.Query(Booking);
      query.equalTo("objectId", this.eventLocal.id)
      query
        .find()
        .then((results) => {
          if (results[0]) {
            this.time = results[0].attributes.time
            this.loading = false
          }
        })
        .catch((ex) => {
          this.loading = false
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching booking details",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    onSubmit() {
      const Booking = Parse.Object.extend("Booking");
      const Log = Parse.Object.extend("Log");
      const booking = new Booking();
      booking.set("objectId", this.eventLocal.id);

      const log = new Log();
      log.set("booking", booking)
      if (this.log.id) {
        log.set("id", this.log.id)
      }
      log.set("doctor", this.log.doctor)
      log.set("patient", this.log.patient)
      log.set("comments", this.log.comments)

      log.save().then((response) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Log added",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.resetForm()
        this.fetchLogs()
      })
    },
    fetchLogs() {
      this.loadingLogs = true;
      const Log = Parse.Object.extend("Log");
      const Booking = Parse.Object.extend("Booking");

      const query = new Parse.Query(Log);

      const booking = new Booking();
      booking.set("objectId", this.eventLocal.id);

      query.equalTo("booking", booking)

      query
        .find()
        .then((results) => {
          this.logs = results;
          this.loadingLogs = false;
        })
        .catch((ex) => {
          this.loadingLogs = false;
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching logs",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    editLog(log) {
      this.showForm = true
      this.log = {
        doctor: log.attributes.doctor,
        patient: log.attributes.patient,
        comments: log.attributes.comments,
        id: log.id
      }
    },
    deleteLog(log) {
      log.destroy({}).then((response) => {

        this.toast({
          component: ToastificationContent,
          props: {
            title: "Log deleted",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.resetForm()
        this.fetchLogs()
      })
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const toast = useToast()

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      guestsOptions,

      // Form Validation
      refFormObserver,
      getValidationState,
      toast,
      convertMinToHr
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.button-icon {
  margin-right: 4px;
}

.log-container {
  border-radius: 8px;
  border: 1px solid #EDEDFE;

  .header {
    padding: 6px;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px #EDEDFE;

    .cursor-pointer {
      color: #484848;
      margin: 3px;
    }
  }

  img {
    margin-left: 4px;
    margin-right: 4px;
  }

  .name {
    color: #7367F0;
    margin-top: 2px;
  }

  .comment {
    font-size: 14px;
  }

  .updatedAt {
    font-style: italic;
    margin-bottom: 0 !important;
    font-size: 10px;
  }

  .time {
    font-size: 12px;
  }

}

.icon {
  height: 20px;
}
</style>
